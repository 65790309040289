<template>
  <b-col md="12" lg="8" xl="6">
    <b-card-group class="align-items-center bg-white h-100">
      <b-card no-body class="border-0 rounded-0">
        <b-card-body class="pt-0 card-body-info">
          <b-form
            class="register-email form-inputs"
            novalidate
            @submit.prevent="onSubmitRegister"
          >
            <h3 class="title pb-md-3 pb-xxl-5 text-center">
              {{ $t("general_inputs.forgot_password") }}
            </h3>
            <transition-alert>
              <template v-if="msgErrors.length">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="danger"
                >
                  <span
                    class="d-block"
                    v-for="(msg, index) in msgErrors"
                    :key="index"
                    >{{ msg }}</span
                  >
                </b-alert>
              </template>
            </transition-alert>
            <transition-alert>
              <template v-if="msgSuccess">
                <b-alert
                  :show="dismissCountDown"
                  dismissible
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChange"
                  variant="success"
                >
                  {{ msgSuccess }}
                </b-alert>
              </template>
            </transition-alert>

            <b-input-group class="mb-1 mb-md-0 mt-3 input-group-info mt-md-0">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-2 pr-lg-3 pt-1">{{
                    $t("general_inputs.email")
                  }}</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email mb-4">
                <b-form-input
                  class="text-truncate"
                  type="email"
                  disabled
                  v-model="formRegister.email"
                  autocomplete="off"
                />
              </div>
            </b-input-group>
            <b-input-group class="mb-4 pb-2 pb-md-0 mb-md-0 input-group-info align-items-baseline">
              <b-input-group-prepend
                ><b-input-group-text class="border-0 rounded-0"
                  ><span class="pr-2 pr-lg-4">{{
                    $t("general_inputs.password")
                  }}</span></b-input-group-text
                ></b-input-group-prepend
              >
              <div class="group-email group-password">
                <b-form-input
                  class="text-truncate"
                  :type="type"
                  v-model="formRegister.newPassword"
                  minLength="8"
                  maxLength="32"
                  autocomplete="off"
                />
                <div class="confirm-input-password">
                  <a v-b-toggle href="#confirm-collapse" @click.prevent
                    ><span>▼</span>
                    {{ $t("general_inputs.please_confirm_pass") }}</a
                  >
                  <b-collapse id="confirm-collapse" class="mt-1" visible>
                    <template>
                      <b-form-input
                        class="text-truncate"
                        :type="type_confirm"
                        v-model="formRegister.newPasswordConfirmed"
                        minLength="8"
                        maxLength="32"
                        autocomplete="off"
                      />
                    </template>
                  </b-collapse>
                </div>
                <span class="chars">{{
                  $t("general_inputs.rule_password")
                }}</span>
              </div>
            </b-input-group>

            <p class="text-right d-m-flex pt-3 mt-2">
              <b-link class="btn btn-outline-secondary" to="/">{{
                $t("general_inputs.btn_cancel")
              }}</b-link>
              <b-button
                class="primary px-4"
                type="submit"
                :disabled="isSubmitted"
                >{{ $t("general_inputs.btn_reset_password") }}
                <esports-loading-button
                  v-if="isSubmitted"
                  width="30px"
                  height="30px"
                  outerBorder="#333"
                  innerBorder="#eee"
              /></b-button>
            </p>
          </b-form>
        </b-card-body>
      </b-card>
    </b-card-group>
  </b-col>
</template>

<script>
import { dismissCount } from "@/mixins";
export default {
  name: "CreateNewPassword",
  props: {
    formRegisterData: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitted: false,
      msgErrors: [],
      msgSuccess: null,
      formRegister: {
        email: "",
        newPassword: "",
        newPasswordConfirmed: "",
      },
      type: "password",
      type_confirm: "password"
    };
  },
  watch: {
    msgErrors(nVal) {
      if (nVal) {
        this.dismissCountDown = this.dismissSecs;
      }
    },
  },
  mixins: [dismissCount],
  mounted() {
    this.formRegister = { ...this.formRegisterData };
  },
  methods: {
    async onSubmitRegister() {
      this.isSubmitted = true;

      const result = await this.$store
        .dispatch("auth/updatePassword", this.formRegister)
        .catch((err) => {
          this.isSubmitted = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
        });

      this.isSubmitted = false;

      if (result) {
        this.msgSuccess = result.message;
        this.msgErrors = [];

        setTimeout(() => {
          this.$router.push({ path: "/" });
        }, 1500);
      }
    },
  },
};
</script>