<template>
  <div class="app page-bg">
    <esports-header-authen />
    <b-container class="login-page register-page" :class="[{'verify-code-page': isVerifyCode}]">
      <b-row class="justify-content-center w-100">
        <component
          @activeComponent="activeComponent"
          :formRegisterData="formRegisterData"
          :is="currentTabComponent"
        ></component>
      </b-row>
    </b-container>
    <esports-footer-authen />
  </div>
</template>

<script>
/* eslint-disable */
import ForgotPasswordByEmail from "./ForgotPassword/ForgotPasswordByEmail";
import VerifyCode from "./ForgotPassword/VerifyCode";
import CreateNewPassword from "./ForgotPassword/CreateNewPassword";

export default {
  name: "ForgotPasswordPage",
  title: "forgot_password",
  components: {
    ForgotPasswordByEmail,
    VerifyCode,
    CreateNewPassword,
  },
  data() {
    return {
      isSubmitted: false,
      formRegisterData: null,
      currentTabComponent: ForgotPasswordByEmail,
      isVerifyCode: false,
    };
  },
  methods: {
    activeComponent(payload) {
      this.formRegisterData = payload.user;
      this.currentTabComponent = payload.name;

      if(payload.name === 'VerifyCode') {
        this.isVerifyCode = true;
        return;
      }
      this.isVerifyCode = false;
    },
  },
};
</script>